<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Precios de Productos</h4>
            <div class="small text-muted">Administración general de precios de costo y venta</div>
          </b-col>
        </b-row>
      </b-card>

      <b-row>
        <b-col lg="9">
          <b-row>            
            <b-col lg="12">
              <b-row>                        
                <b-col lg="4">
                  <Widget :configWidget="configWidget" reference="valid_products" :hasAccess="configWidget.elements.validProducts" />
                  <Widget :configWidget="configWidget" reference="list_price_without_update" :hasAccess="configWidget.elements.listPriceWithoutUpdate" />
                  <Widget :configWidget="configWidget" reference="list_cant_products_by_suppliers" :hasAccess="configWidget.elements.listCantProductsBySuppliers" />                  
                </b-col>
                <b-col lg="8">
                  <Widget :configWidget="configWidget" reference="list_last_products_update" :hasAccess="configWidget.elements.listLastProductsUpdate" />
                </b-col>                
              </b-row>
            </b-col>
          </b-row>
        </b-col>  
        <b-col md="3">
          <b-list-group class="mb-2">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openSuppliersProducts()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Precios de Productos</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Administrador de listas de precios de cada proveedor
              </p>
            </b-list-group-item>
          </b-list-group>

          <b-list-group>
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openImportLog()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Importaciones</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Muestra todas las importaciones realizadas
              </p>
            </b-list-group-item>
          </b-list-group>          

          <b-list-group>
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openUpdateExternal()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Actualizaciones Externas</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Muestra todas las actualizaciones de precio desde servicios externos
              </p>
            </b-list-group-item>
          </b-list-group>                    
        </b-col>        
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'
  import Widget from '@/components/inc/widget/widget'

  export default {
    components: {
      Widget
    },
    data: () => {
      return {
        access: {
          module_id: Modules.COSTOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'dashboard',
          elements: {}
        },
        configWidget: {
          module_id: Modules.COSTOS,
          profile_id: Profiles.PERSONAL,
          elements: { 
            validProducts: true,
            listCantProductsBySuppliers: true,
            listLastProductsUpdate: true,
            listPriceWithoutUpdate: true,
          }
        },
        primaryColor: '',
      }
    },
    created () {
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */

      /* Configurar permisos de los widgets */
      this.configWidget.elements.validProducts = Helper.hasAccessWidget(this.configWidget, 'valid_products')
      this.configWidget.elements.listCantProductsBySuppliers = Helper.hasAccessWidget(this.configWidget, 'list_cant_products_by_suppliers')
      this.configWidget.elements.listPriceWithoutUpdate = Helper.hasAccessWidget(this.configWidget, 'list_price_without_update')
      this.configWidget.elements.listLastProductsUpdate = Helper.hasAccessWidget(this.configWidget, 'list_last_products_update')
      /* Fin configuracion */
    },
    mounted() {
      this.setup()
    }, 
    methods: {
      setup () {        
        if(Session.getSession().settings) {    
          this.primaryColor = Session.getSession().settings.color_primary
                    
          this.createCSS()
        }
      },         
      createCSS() {
        var hoja = document.createElement('style')
        hoja.innerHTML = ""      
        hoja.innerHTML = hoja.innerHTML  + ".list-group-item-action:hover, .list-group-item-action:focus { background-color: " + this.primaryColor + "; }"

        document.body.appendChild(hoja);
      },
      openSuppliersProducts() {
        this.$router.push({ name: 'CostsStaffPriceListSuppliers' })
      },
      openImportLog() {
        this.$router.push({ name: 'CostsStaffPriceListSuppliersImportLog' })
      },
      openUpdateExternal() {
        this.$router.push({ name: 'CostsStaffPriceListSuppliersUpdateExternal' })
      }
    }
  }
</script>

<style>

</style>